/* THIS FILE IS AUTOGENERATED by scripts/index_tutorials.ts. DO NOT EDIT. */
import basicControlsMd from './BasicControls.md';
import connectingDevicesMd from './ConnectingDevices.md';
import gCodeMd from './GCode.md';
import lockstepConfigurationMd from './LockstepConfiguration.md';
import microscopeMd from './Microscope.md';
import microscopePresetsMd from './MicroscopePresets.md';
import microscopeSetupMd from './MicroscopeSetup.md';
import oscilloscopeMd from './Oscilloscope.md';
import oscilloscopeBasicsMd from './OscilloscopeBasics.md';
import oscilloscopeExternalTriggerMd from './OscilloscopeExternalTrigger.md';
import settingsMd from './Settings.md';
import sharingConnectionsMd from './SharingConnections.md';
import terminalMd from './Terminal.md';
import triggersMd from './Triggers.md';
export const TUTORIALS = {
    BasicControls: basicControlsMd,
    ConnectingDevices: connectingDevicesMd,
    GCode: gCodeMd,
    LockstepConfiguration: lockstepConfigurationMd,
    Microscope: microscopeMd,
    MicroscopePresets: microscopePresetsMd,
    MicroscopeSetup: microscopeSetupMd,
    Oscilloscope: oscilloscopeMd,
    OscilloscopeBasics: oscilloscopeBasicsMd,
    OscilloscopeExternalTrigger: oscilloscopeExternalTriggerMd,
    Settings: settingsMd,
    SharingConnections: sharingConnectionsMd,
    Terminal: terminalMd,
    Triggers: triggersMd,
};
export const METADATA = {
    BasicControls: {
        key: 'BasicControls',
        title: 'Basic Controls',
        category: 'Generic Apps',
        icon: 'BasicMovement',
        poster: 'BasicControls',
        date: new Date('2025-03-25T21:21:19.519Z'),
        parent: null,
    },
    ConnectingDevices: {
        key: 'ConnectingDevices',
        title: 'Connecting Devices',
        category: 'Getting Started',
        icon: 'Launcher',
        poster: 'ConnectingDevices',
        date: new Date('2025-04-03T17:48:20.947Z'),
        parent: null,
    },
    GCode: {
        key: 'GCode',
        title: 'G-Code',
        category: 'Specialized Apps',
        icon: 'GCode',
        poster: 'GCode',
        date: new Date('2025-04-03T19:40:51.804Z'),
        parent: null,
    },
    LockstepConfiguration: {
        key: 'LockstepConfiguration',
        title: 'Lockstep Configuration',
        category: 'Specialized Apps',
        icon: 'Lockstep',
        poster: 'LockstepConfiguration',
        date: new Date('2025-04-03T17:48:33.250Z'),
        parent: null,
    },
    Microscope: {
        key: 'Microscope',
        title: 'Microscope',
        category: 'Specialized Apps',
        icon: 'Microscope',
        poster: 'Microscope',
        date: new Date('2025-04-03T17:47:14.657Z'),
        parent: null,
    },
    MicroscopePresets: {
        key: 'MicroscopePresets',
        title: 'Microscope Presets',
        category: 'Specialized Apps',
        icon: 'Microscope',
        poster: 'MicroscopePresets',
        date: new Date('2025-04-03T17:49:09.661Z'),
        parent: 'Microscope',
    },
    MicroscopeSetup: {
        key: 'MicroscopeSetup',
        title: 'Microscope Setup',
        category: 'Specialized Apps',
        icon: 'Microscope',
        poster: 'Microscope',
        date: new Date('2025-04-03T17:50:24.730Z'),
        parent: 'Microscope',
    },
    Oscilloscope: {
        key: 'Oscilloscope',
        title: 'Oscilloscope',
        category: 'Specialized Apps',
        icon: 'Oscilloscope',
        poster: 'OscilloscopeBasics',
        date: new Date('2025-04-03T17:47:14.658Z'),
        parent: null,
    },
    OscilloscopeBasics: {
        key: 'OscilloscopeBasics',
        title: 'Oscilloscope Basics',
        category: 'Specialized Apps',
        icon: 'Oscilloscope',
        poster: 'OscilloscopeBasics',
        date: new Date('2025-04-03T17:49:19.841Z'),
        parent: 'Oscilloscope',
    },
    OscilloscopeExternalTrigger: {
        key: 'OscilloscopeExternalTrigger',
        title: 'Oscilloscope External Trigger',
        category: 'Specialized Apps',
        icon: 'Oscilloscope',
        poster: 'OscilloscopeExternalTrigger',
        date: new Date('2025-04-03T17:49:35.869Z'),
        parent: 'Oscilloscope',
    },
    Settings: {
        key: 'Settings',
        title: 'Device Settings',
        category: 'Generic Apps',
        icon: 'DeviceSettings',
        poster: 'Settings',
        date: new Date('2025-04-03T17:49:43.633Z'),
        parent: null,
    },
    SharingConnections: {
        key: 'SharingConnections',
        title: 'Sharing Connections',
        category: 'Getting Started',
        icon: 'Launcher',
        poster: 'SharingConnections',
        date: new Date('2025-04-03T17:47:14.659Z'),
        parent: null,
    },
    Terminal: {
        key: 'Terminal',
        title: 'Terminal',
        category: 'Generic Apps',
        icon: 'Terminal',
        poster: 'Terminal',
        date: new Date('2025-04-03T17:49:59.026Z'),
        parent: null,
    },
    Triggers: {
        key: 'Triggers',
        title: 'Triggers',
        category: 'Specialized Apps',
        icon: 'Triggers',
        poster: 'Triggers',
        date: new Date('2025-04-03T17:49:51.102Z'),
        parent: null,
    },
};
