import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Redirect, useParams } from 'react-router-dom';
import { NavigationBar } from '../navigation_bar';
import { PageTitle } from '../components/PageTitle';
import { TableOfContents } from './TableOfContents';
import { Tutorial } from './Tutorial';
import { TUTORIALS } from './markdown/content_generated';
import { TutorialFooter } from './TutorialFooter';
export const ZaberLauncherTutorials = () => {
    var _a;
    const parms = useParams();
    const tutorialName = (_a = parms === null || parms === void 0 ? void 0 : parms.name) !== null && _a !== void 0 ? _a : null;
    const known = (tutorialName != null) && (Object.keys(TUTORIALS).includes(tutorialName));
    return _jsxs("div", { className: "zaber-launcher-tutorials page", children: [_jsx(PageTitle, { children: "Zaber Launcher Tutorials" }), _jsx(NavigationBar, {}), _jsxs("div", { className: "content", children: [!tutorialName && _jsx(TableOfContents, {}), tutorialName && !known && _jsx(Redirect, { to: "/404" }), tutorialName && known && _jsx(Tutorial, { tutorial: tutorialName })] }), _jsx(TutorialFooter, {})] });
};
